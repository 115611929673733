<template>
  <div class="initial-setup">
    <h2 class="text-center mb-4 mt-6">
      Quick Setup
    </h2>
    <v-stepper
      v-model="e6"
      class="mx-auto"
      style="max-width: 80%;"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        General
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-text-field
          ref="companyNameField"
          v-model="form.company_name"
          class="mt-1"
          outlined
          label="Company Name"
          placeholder="Awesome name"
          :rules="[v => !!v || 'Company name is required']"
          autocomplete="text"
          :error="form.errors.has('company_name')"
          :error-messages="form.errors.get('company_name')"
          required
        ></v-text-field>
        <v-btn
          color="primary"
          @click="next(2)"
        >
          Continue
        </v-btn>
        <v-btn
          v-if="e6 > 1"
          text
          @click="next(e6 - 1)"
        >
          Previous
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :complete="e6 > 2"
        step="2"
      >
        Configure router
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-text-field
          v-model="form.mikrotik_host"
          class="mt-1"
          outlined
          label="MikroTik Host"
          placeholder="192.168.88.1"
          :rules="[v => !!v || 'MikroTik Host is required', isValidIpAddress]"
          autocomplete="text"
          :error="form.errors.has('mikrotik_host')"
          :error-messages="form.errors.get('mikrotik_host')"
          hint="IP address of your MikroTik router"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.mikrotik_username"
          class="mt-1"
          outlined
          label="MikroTik Username"
          placeholder="admin"
          :rules="[v => !!v || 'MikroTik Username is required']"
          autocomplete="text"
          :error="form.errors.has('mikrotik_username')"
          :error-messages="form.errors.get('mikrotik_username')"
          hint="Username of your MikroTik router"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.mikrotik_password"
          class="mt-1"
          outlined
          label="MikroTik Password"
          placeholder="············"
          autocomplete="text"
          :error="form.errors.has('mikrotik_password')"
          :error-messages="form.errors.get('mikrotik_password')"
          hint="Password of your MikroTik router"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.mikrotik_port"
          class="mt-1"
          outlined
          label="MikroTik Port"
          placeholder="8728"
          :rules="[v => !!v || 'MikroTik Port is required']"
          autocomplete="text"
          :error="form.errors.has('mikrotik_port')"
          :error-messages="form.errors.get('mikrotik_port')"
          required
        ></v-text-field>
        <div class="mb-2">
          <div v-if="loading || connectionSuccess || connectionFailed">
            <small>Establishing connection to the router... </small>
            <v-progress-circular
              v-if="loading"
              indeterminate
              width="2"
              size="16"
              color="primary"
            ></v-progress-circular>
            <v-icon
              v-if="connectionSuccess"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
            <v-icon
              v-if="connectionFailed"
              style="color: red !important;"
            >
              {{ icons.mdiAlertCircleOutline }}
            </v-icon>
          </div>
          <div v-if="connectionFailed">
            <small style="color: red !important;">Connection failed. {{ errorMessage }} </small>
          </div>
        </div>
        <v-btn
          color="primary"
          :loading="loading"
          @click="connectionSuccess ? next(3) : testConnection()"
        >
          {{ connectionSuccess ? 'Continue' : 'Test Connection' }}
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :complete="e6 > 3"
        step="3"
      >
        Setup router
      </v-stepper-step>
      <v-stepper-content step="3">
        <div v-if="checkUserManagerCalled">
          <small>Checking if 'user-manager' package is installed...</small>
          <v-progress-circular
            v-if="checkingUserManager"
            indeterminate
            width="2"
            size="16"
            color="primary"
          ></v-progress-circular>
          <v-icon
            v-if="userManagerInstalled"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon
            v-if="userManagerInstallFailed"
            style="color: red !important;"
          >
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </div>
        <div v-if="downloadUserManagerCalled">
          <small>Downloading 'user-manager' package...</small>
          <v-progress-circular
            v-if="downloadingUserManager"
            indeterminate
            width="2"
            size="16"
            color="primary"
          ></v-progress-circular>
          <v-icon
            v-if="userManagerDownloaded"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon
            v-if="userManagerDownloadFailed"
            style="color: red !important;"
          >
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </div>
        <div v-if="uploadUserManagerCalled">
          <small>Uploading 'user-manager' package to the router...</small>
          <v-progress-circular
            v-if="uploadingUserManager"
            indeterminate
            width="2"
            size="16"
            color="primary"
          ></v-progress-circular>
          <v-icon
            v-if="userManagerUploaded"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon
            v-if="userManagerUploadFailed"
            style="color: red !important;"
          >
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </div>
        <div v-if="installUserManagerCalled">
          <small>Installing 'user-manager' package. Router will reboot to apply changes</small>
          <v-progress-circular
            v-if="installingUserManager"
            indeterminate
            width="2"
            size="16"
            color="primary"
          ></v-progress-circular>
          <v-icon
            v-if="userManagerInstalled"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon
            v-if="userManagerInstallFailed"
            style="color: red !important;"
          >
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </div>
        <div v-if="listingInterfacesCalled">
          <small>Listing interfaces...</small>
          <v-progress-circular
            v-if="listingInterfaces"
            indeterminate
            width="2"
            size="16"
            color="primary"
          ></v-progress-circular>
          <v-icon
            v-if="interfacesListed"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
          <h4 class="mt-4">Please select interfaces that will be configured for Hotspot Getway.</h4>
          <small>A new bridge will be created where all selected interfaces will be added.</small>
          <v-checkbox
            v-for="iface in ifaces"
            :key="iface['.id']"
            v-model="form.selected_interfaces"
            class="ml-2"
            type="checkbox"
            :value="iface.name"
            :label="iface.name"
          />
        </div>
        <v-btn
          color="primary"
          class="mt-2"
          @click="next(4)"
        >
          Continue
        </v-btn>
        <v-btn
          text
          @click="next(2)"
        >
          Previous
        </v-btn>
      </v-stepper-content>
      <v-stepper-step step="4">
        Configure payments information
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-text-field
          v-model="form.paybill_number"
          class="mt-1"
          outlined
          label="M-pesa Paybill Number"
          placeholder="8728"
          :rules="[v => !!v || 'Paybill Number is required']"
          autocomplete="text"
          :error="form.errors.has('paybill_number')"
          :error-messages="form.errors.get('paybill_number')"
          required
        ></v-text-field>
        <v-btn
          color="primary"
          :loading="submittingForm"
          @click="submit"
        >
          Finish
        </v-btn>
        <v-btn
          text
          @click="next(3)"
        >
          Previous
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>

import axios from 'axios'
import Form from 'vform'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'

export default {
  data() {
    return {
      e6: 1,
      loading: false,
      checkingUserManager: false,
      userManagerInstalled: false,
      userManagerInstallFailed: false,
      downloadingUserManager: false,
      userManagerDownloaded: false,
      userManagerDownloadFailed: false,
      uploadingUserManager: false,
      userManagerUploaded: false,
      userManagerUploadFailed: false,
      checkUserManagerCalled: false,
      installUserManagerCalled: false,
      downloadUserManagerCalled: false,
      uploadUserManagerCalled: false,
      listingInterfacesCalled: false,
      submittingForm: false,
      connectionSuccess: false,
      connectionFailed: false,
      installingUserManager: false,
      listingInterfaces: false,
      interfacesListed: false,
      ifaces: [],
      errorMessage: '',
      form: new Form({
        company_name: '',
        mikrotik_host: '192.168.88.1',
        mikrotik_username: 'admin',
        mikrotik_password: '',
        mikrotik_port: '8728',
        selected_interfaces: [],
        paybill_number: '',
      }),
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
      },
    }
  },
  computed: {
    isValidIpAddress() {
      return value => {
        const ipPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

        return ipPattern.test(value) || 'Please enter a valid IP address'
      }
    },
  },
  watch: {
    'form.mikrotik_host': function () {
      this.connectionSuccess = false
    },
    'form.mikrotik_username': function () {
      this.connectionSuccess = false
    },
    'form.mikrotik_password': function () {
      this.connectionSuccess = false
    },
    'form.mikrotik_port': function () {
      this.connectionSuccess = false
    },
  },
  methods: {
    async submit() {
      this.submittingForm = true
      this.form.post('initial-setup')
        .then(response => {
          this.$toast.success(response.data.message)
          this.$router.push({ name: 'dashboard' })
          // this.rebootRouter()
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.submittingForm = false
        })
    },
    async testConnection() {
      this.loading = true
      this.connectionSuccess = false
      this.connectionFailed = false
      this.errorMessage = ''

      try {
        await this.form.post('initial-setup/establish-mikrotik-connection')
        this.connectionSuccess = true
      } catch (error) {
        this.connectionFailed = true
        this.errorMessage = error.response.data.error
      } finally {
        this.loading = false
      }
    },
    checkUserManagerPackage() {
      this.checkUserManagerCalled = true
      this.checkingUserManager = true
      this.userManagerInstallFailed = false
      axios.get('initial-setup/check-user-manager-package')
        .then(response => {
          if (response.data.status === 'success') {
            this.userManagerInstalled = true
            this.listInterfaces()
          } else {
            this.downloadUserManager()
          }
        })
        .catch(() => {
          this.userManagerInstallFailed = true
        })
        .finally(() => {
          this.checkingUserManager = false
        })
    },
    downloadUserManager() {
      this.downloadUserManagerCalled = true
      this.downloadingUserManager = true
      this.userManagerDownloadFailed = false
      axios.get('initial-setup/download-user-manager-package')
        .then(response => {
          this.userManagerDownloaded = true
          if (response.data.status === 'success') {
            this.uploadUserManager(response.data.package_name)
          } else {
            this.userManagerDownloadFailed = true
          }
        })
        .catch(error => {
          this.userManagerDownloadFailed = true
          console.log(error)
        })
        .finally(() => {
          this.downloadingUserManager = false
        })
    },
    uploadUserManager(packageName) {
      this.uploadUserManagerCalled = true
      this.uploadingUserManager = true
      this.userManagerUploadFailed = false
      axios.post(`initial-setup/upload-user-manager-package/${packageName}`)
        .then(() => {
          this.userManagerUploaded = true
          this.installUserManager()
        })
        .catch(error => {
          this.userManagerUploadFailed = true
          console.log(error)
        })
        .finally(() => {
          this.uploadingUserManager = false
        })
    },
    installUserManager() {
      this.installUserManagerCalled = true
      this.installingUserManager = true
      this.userManagerInstallFailed = false
      axios.post('initial-setup/install-user-manager-package')
        .then(() => {
          this.userManagerInstalled = true
        })
        .catch(error => {
          this.userManagerInstallFailed = true
          console.log(error)
        })
        .finally(() => {
          this.installingUserManager = false
        })
    },
    listInterfaces() {
      this.listingInterfacesCalled = true
      this.listingInterfaces = true
      axios.get('initial-setup/list-interfaces')
        .then(response => {
          this.interfacesListed = true
          this.ifaces = response.data.interfaces
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.listingInterfaces = false
        })
    },
    rebootRouter() {
      axios.get('reboot-router')
        .then(() => {
          this.$toast.success('Rebooting router...')
        })
        .catch(error => {
          console.log(error)
        })
    },
    isFirstStepValid() {
      return this.$refs.companyNameField.validate()
    },
    next(step) {
      if (step === 2 && !this.isFirstStepValid()) {
        return
      }
      if (step === 3) {
        this.checkUserManagerPackage()
      }
      this.e6 = step
    },
  },
}
</script>

<style scoped>
.red-icon {
  color: red !important;
}
</style>
