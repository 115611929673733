import { render, staticRenderFns } from "./InitialSetup.vue?vue&type=template&id=3a087f42&scoped=true&"
import script from "./InitialSetup.vue?vue&type=script&lang=js&"
export * from "./InitialSetup.vue?vue&type=script&lang=js&"
import style0 from "./InitialSetup.vue?vue&type=style&index=0&id=3a087f42&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a087f42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VIcon,VProgressCircular,VStepper,VStepperContent,VStepperStep,VTextField})
